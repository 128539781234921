import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ActionStatusString, ResidentMailActionType } from '@ui/shared/models';
import { ActionState } from 'libs/state-utils';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { notificationConfig } from 'tenant-pool/config';
import { environment } from 'tenant-pool-env';
import * as fromResidentState from '../../../../+state/resident';

@Component({
  selector: 'app-deeplink-redirect',
  templateUrl: './deeplink-redirect.component.html',
  styleUrls: ['./deeplink-redirect.component.scss']
})
export class DeeplinkRedirectComponent implements OnInit {
  public info: { success: boolean; message: string } | undefined;

  // TODO should be in another place, component getting bloated. Need service as well.
  private webAppMap: { [key: string]: string } = {
    bergedorfbille: 'bergedorf-bille'
  };

  /* eslint-disable */
  constructor(
    private route: ActivatedRoute,
    private store: Store<fromResidentState.ResidentState>
  ) {}

  ngOnInit() {
    const {
      appClientName,
      token,
      residentVerificationCode,
      customerIdent,
      registerPath,
      appRedirectPath,
      appRedirectAction,
      erpIdentifiers
    } = this.route.snapshot.params as {
      appClientName: string;
      token: string;
      residentVerificationCode: string;
      customerIdent: string;
      registerPath: string;
      appRedirectPath: string;
      appRedirectAction: ResidentMailActionType;
      erpIdentifiers: string;
    };

    let erpIdentifiersEncoded;
    if (erpIdentifiers) {
      erpIdentifiersEncoded = encodeURIComponent(erpIdentifiers);
    }

    this.checkArgumentsAndPrintDebugInformation(
      appClientName,
      token,
      customerIdent,
      registerPath,
      appRedirectPath
    );

    /* Legacy code: At first we only had one variable that was set by the BE. Current live apps rely on this path. */
    const legacyDeeplinkPath = registerPath?.replace('*', '/');
    /*
     * New code to allow more flexibility:
     * appRedirectAction is set inside the email template and tells the app which EP should be called
     * as an (re-)action to the email.
     */
    const deeplinkPath = appRedirectPath?.replace('*', '/');

    /* When no verificationCode is used the placeholder from the backend remains and is removed here  */
    const verificationCode =
      residentVerificationCode &&
      residentVerificationCode !== '${residentVerificationCode}'
        ? residentVerificationCode
        : '';

    if (deeplinkPath) {
      const observedActionState = token
        ? this.callApollo(
            appRedirectAction,
            customerIdent,
            token,
            verificationCode
          )
        : null;

      if (observedActionState) {
        this.handleApolloResult(
          observedActionState,
          deeplinkPath,
          appRedirectAction,
          appClientName,
          token,
          verificationCode,
          erpIdentifiersEncoded
        );
      } else {
        window.location.href = this.getRedirectUrl(
          deeplinkPath,
          appRedirectAction,
          undefined,
          appClientName,
          token,
          verificationCode,
          erpIdentifiersEncoded
        );
      }
    } else if (legacyDeeplinkPath) {
      /*
       * only the email RS_RESET_PASSWORD uses the legacyRegisterPath. Since Wankendorfer is live and uses this email
       * we will keep this deeplink URL for now as legacy
       */
      const url = this.getRedirectUrl(
        legacyDeeplinkPath,
        undefined,
        undefined,
        appClientName,
        token,
        verificationCode
      );
      // show message to open this link on the phone where the password can be reset.
      this.handleInfo(
        ResidentMailActionType.RESET_PASSWORD,
        ActionStatusString.SUCCESS
      );
      window.location.href = url;
    }
  }

  private handleApolloResult(
    observedActionState: Observable<ActionState>,
    deeplinkPath: string,
    action: ResidentMailActionType,
    clientName: string,
    token: string,
    verificationCode: string,
    erpIdentifiers: string
  ) {
    observedActionState
      .pipe(
        filter(actionState => !actionState.pending),
        take(1)
      )
      .subscribe(actionState => {
        const status = this.getActionStatusString(actionState);
        this.handleInfo(action, status);

        const url = this.getRedirectUrl(
          deeplinkPath,
          action,
          status,
          clientName,
          token,
          verificationCode,
          erpIdentifiers
        );

        window.location.href = url;
      });
  }

  private getRedirectUrl(
    deeplinkPath: string,
    action: ResidentMailActionType = undefined,
    status: string = undefined,
    clientName: string,
    token: string,
    verificationCode: string = '',
    erpIdentifiers?: string
  ) {
    const hasWebDomain = !!this.webAppMap[clientName];
    const segments = [deeplinkPath];

    if (action) {
      segments.push(action);
    }
    if (status) {
      segments.push(status);
    }
    if (token) {
      segments.push(token);
    }
    if (verificationCode) {
      segments.push(verificationCode);
    }
    if (erpIdentifiers) {
      segments.push(erpIdentifiers);
    }

    const appDeeplink = `${clientName}://app/${segments.join('/')}`;
    const webAppUrl = `https://${this.webDomain(
      clientName,
      environment.production
    )}/${segments.join('/')}`;

    return this.isDesktop() && hasWebDomain ? webAppUrl : appDeeplink;
  }

  private getActionStatusString(actionState: ActionState): ActionStatusString {
    if (actionState.done) {
      return ActionStatusString.SUCCESS;
    } else if (actionState.error) {
      return ActionStatusString.ERROR;
    }
    return ActionStatusString.NONE;
  }

  private callApollo(
    appRedirectAction: string,
    customerIdent: string,
    token: string,
    residentVerificationCode: string
  ): Observable<ActionState> {
    if (appRedirectAction === ResidentMailActionType.VERIFY_EMAIL) {
      this.store.dispatch(
        fromResidentState.verifyEmail({
          token,
          residentVerificationCode,
          customerIdent
        })
      );
      return this.store.select(
        fromResidentState.getResidentVerifyEmailActionState
      );
    } else if (appRedirectAction === ResidentMailActionType.CHANGE_EMAIL) {
      this.store.dispatch(
        fromResidentState.changeEmail({ token, customerIdent })
      );
      return this.store.select(
        fromResidentState.getResidentChangeEmailActionState
      );
    }
  }
  /* eslint-enable */

  private handleInfo = (
    action: ResidentMailActionType,
    status: ActionStatusString
  ) => {
    let message: string;
    if (action === ResidentMailActionType.VERIFY_EMAIL) {
      const { success, error } = notificationConfig.resident.verifyEmail;
      message = status === ActionStatusString.SUCCESS ? success : error;
    } else if (action === ResidentMailActionType.CHANGE_EMAIL) {
      const { success, error, pending } =
        notificationConfig.resident.changeEmail;
      if (status === ActionStatusString.SUCCESS) {
        message = success;
      } else if (status === ActionStatusString.ERROR) {
        message = error;
      } else {
        message = pending;
      }
    } else if (action === ResidentMailActionType.RESET_PASSWORD) {
      const { pending } = notificationConfig.resident.resetPassword;
      message = pending;
    }
    this.info = { success: status === ActionStatusString.SUCCESS, message };
  };

  private isDesktop = () => {
    return !navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
    );
  };

  private webDomain = (clientName: string, isProduction: boolean) => {
    return `portal.${this.webAppMap[clientName]}${this.env(isProduction)}.com`;
  };

  private env = (isProduction: boolean) => {
    return isProduction ? '' : '.dev.resident.immomio';
  };

  /**
   * Simple debug information should the cloud config not be set to easily debug the issue.
   * TODO could be updated to actually show an error message
   * @param appClientName
   * @param token
   * @param customerIdent
   * @param registerPath
   * @param appRedirectPath
   * @private
   */
  private checkArgumentsAndPrintDebugInformation(
    appClientName: string,
    token: string,
    customerIdent: string,
    registerPath: string,
    appRedirectPath: string
  ) {
    if (!appClientName) {
      console.error('app client name is not set.');
    }

    if (!token) {
      console.error('token is not set.');
    }

    if (!customerIdent) {
      console.error('customer identification is not set.');
    }

    if (!registerPath) {
      console.error('registration path is not set.');
    }

    if (!appRedirectPath) {
      console.error('app redirect path is not set.');
    }
  }
}
