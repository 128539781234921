import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { SessionStorageService } from 'libs/infrastructure';

import { MainPageNavigation, storageKeys } from 'tenant-pool/config';

@Component({
  selector: 'app-proposal-offered',
  template: ''
})
export class ProposalOfferedComponent implements OnInit {
  constructor(
    private store: Store<fromBaseState.BaseState>,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const { applicationId } = this.route.snapshot.queryParams;
    const stateAfterAuth = {
      pathAfterAuth: `/properties/expose/${String(applicationId)}`
    };

    this.sessionStorage.setItem(storageKeys.stateAfterAuth, stateAfterAuth);

    this.store.dispatch(
      new fromBaseState.Go({
        path: [MainPageNavigation.LOGIN]
      })
    );
  }
}
