import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { InfrastructureConfig } from 'libs/infrastructure';
import { KeycloakTenantWebService } from 'libs/infrastructure/keycloak-authentication-module/services/keycloak-tenant-web.service';
import { ModuleConfig } from './model';
import { KEYCLOAK_OPTIONS } from './contant';
import { DeepLinkService } from './services';

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class KeycloakTenantAuthenticationModule {
  constructor(
    private keycloakWebService: KeycloakTenantWebService,
    @Inject(INFRASTRUCTURE_CONFIG) private infrastructure: InfrastructureConfig
  ) {
    void this.keycloakWebService.init();
  }

  public static forRoot(
    config = new ModuleConfig()
  ): ModuleWithProviders<KeycloakTenantAuthenticationModule> {
    return {
      ngModule: KeycloakTenantAuthenticationModule,
      providers: [
        HttpClientModule,
        { provide: KEYCLOAK_OPTIONS, useValue: config.keycloakConfig },
        KeycloakTenantWebService,
        DeepLinkService,
        JwtHelperService
      ]
    };
  }
}
