import { Directive, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ThemeService } from './theme.service';
import { Theme } from './symbols';

@UntilDestroy()
@Directive({
  selector: '[appTheme]'
})
export class ThemeDirective implements OnInit {
  constructor(
    private themeService: ThemeService,
    @Inject(DOCUMENT) private documentRef: Document
  ) {}

  ngOnInit() {
    const active = this.themeService.getActiveTheme;
    if (active) {
      this.updateTheme(active);
    }

    this.themeService.themeChange
      .pipe(untilDestroyed(this))
      .subscribe((theme: Theme) => this.updateTheme(theme));
  }

  public updateTheme(theme: Theme) {
    // project properties onto the element
    Object.keys(theme.properties).map(key =>
      this.documentRef.body.style.setProperty(key, theme.properties[key])
    );

    // remove old theme
    Object.keys(this.themeService.themes).map(key => {
      if (this.themeService.themes[key].name !== theme.name) {
        return this.documentRef.body.classList.remove(
          `${String(this.themeService.themes[key].name)}-theme`
        );
      }
    });

    // alias element with theme name
    this.documentRef.body.classList.add(`${theme.name}-theme`);
    if (theme?.pageTitle) {
      this.setPageTitle(theme.pageTitle);
    }
    if (theme?.favicon) {
      this.setFavicon(theme);
    }
  }

  public setPageTitle(title: string) {
    this.documentRef.title = title;
  }

  public setFavicon(theme: Theme) {
    const head = this.documentRef.head;
    const linkTags = Array.from(head.getElementsByTagName('link'));
    const faviconBig =
      linkTags.find((link: HTMLLinkElement) => link.sizes.contains('32x32')) ||
      ({} as HTMLLinkElement);
    const favicon =
      linkTags.find((link: HTMLLinkElement) => link.sizes.contains('16x16')) ||
      ({} as HTMLLinkElement);
    favicon.href = theme?.favicon?.url;
    faviconBig.href = theme?.favicon?.url;
  }
}
