import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { customEmailValidator } from 'libs/components/legacy/form/controls/validation';
import {
  CookiePreference,
  Property,
  SocialLogin,
  StateAfterAuth
} from '@ui/shared/models';
import * as fromBaseState from 'libs/infrastructure/base-state';
import * as fromAppState from 'tenant-pool/+state';
import { filter, map } from 'rxjs/operators';
import { MainPageNavigation, storageKeys } from 'tenant-pool/config';
import {
  AuthTokenService,
  LocalStorageService,
  SessionStorageService,
  WINDOW_REF
} from 'libs/infrastructure';
import { getRedirectUrl } from 'libs/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from 'libs/components/legacy';
import { SocialLoginConfirmationModalComponent } from 'tenant-pool/components/social-login-confirmation-modal/social-login-confirmation-modal.component';
import { ActivatedRoute, Params } from '@angular/router';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { ActionState } from 'libs/state-utils';
import { UserData } from 'tenant-pool/models';

interface UserRegisterData extends UserData, SocialLogin {
  name?: string;
  leaveItp?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-direct-apply',
  templateUrl: './direct-apply.component.html',
  styleUrls: ['./direct-apply.component.scss']
})
export class DirectApplyComponent implements OnInit {
  public property: Property;
  public socialLogin: SocialLogin;
  public cookiesPreference: CookiePreference;
  public form: FormGroup;

  public existingSession = false;

  public loading$: Observable<ActionState>;
  public registerProcessing$: Observable<boolean>;
  public registerError$: Observable<string>;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<fromBaseState.BaseState>,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    private authTokenService: AuthTokenService,
    @Inject(WINDOW_REF) private windowRef: Window
  ) {}

  private initForm(): void {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', Validators.required],
      email: [
        '',
        Validators.compose([Validators.required, customEmailValidator])
      ],
      acceptPolicies: [null, Validators.requiredTrue],
      leaveItp: [false]
    });
  }

  private handleQueryParamsData(queryParams: Params) {
    queryParams?.token
      ? this.localStorageService.setItem(
          storageKeys.identityToken,
          queryParams.token
        )
      : null;

    this.socialLogin = {
      isSocialLogin: queryParams.registerAfterSocialLogin === 'true',
      email: queryParams.socialLoginEmail,
      firstName: queryParams.firstName,
      lastName: queryParams.lastName,
      type: queryParams.type
    };
  }

  ngOnInit() {
    this.initForm();

    this.handleQueryParamsData(this.route?.snapshot?.queryParams);

    this.store.dispatch(
      new fromAppState.LoadProperty(this.route.snapshot.params.id)
    );

    this.store.dispatch(new fromBaseState.InitCookiesPreference());

    this.store
      .select(fromAppState.getSetStorageForApplyActionState)
      .pipe(
        filter(storageApplicationState => storageApplicationState.done),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.authTokenService.getToken().access_token) {
          this.store.dispatch(
            new fromBaseState.Go({
              path: [
                MainPageNavigation.PROPERTIES,
                MainPageNavigation.APPLICATIONS
              ]
            })
          );
          this.existingSession = true;
        } else {
          this.existingSession = false;
        }
      });

    this.store
      .select(fromAppState.getProperty)
      .pipe(
        filter(property => !!property),
        untilDestroyed(this)
      )
      .subscribe(property => {
        if (property?.branding?.theme?.name) {
          this.themeService.createTheme(property.branding);
        }
        this.property = property;
        this.localStorageService.setItem(
          storageKeys.customQuestionApplicationId,
          this.property.id
        );

        this.store.dispatch(new fromAppState.SetStorageForApply());
      });

    this.registerProcessing$ = this.store.select(
      fromAppState.getRegisterProcessing
    );

    this.registerError$ = this.store
      .select(fromAppState.getRegisterError)
      .pipe(map(err => err && err.message));

    this.loading$ = this.store.select(fromAppState.getLoadPropertyActionState);

    const personalInformation = this.sessionStorageService.getItem(
      storageKeys.personalInformation
    );

    if (this.socialLogin?.isSocialLogin) {
      if (this.form) {
        this.form.get('password').disable();
        this.form.get('email').disable();
      }
      this.patchForm(this.socialLogin);
    } else if (personalInformation) {
      this.patchForm(personalInformation);
    }
  }

  public onRegister() {
    const userData = {
      brandedCustomerId: this.property?.customer?.id,
      ...this.form.value
    };

    if (this.socialLogin?.isSocialLogin) {
      userData.email = this.socialLogin.email;
      userData.password = '';
      userData.socialLogin = this.socialLogin.isSocialLogin;
    }

    this.store.dispatch(new fromAppState.Register(userData));

    this.sessionStorageService.removeItem(storageKeys.personalInformation);
    this.localStorageService.removeItem(storageKeys.landlordThemeToken);
    this.localStorageService.setItem(storageKeys.portalRegistration, true);
  }

  public onLoginRedirect() {
    this.store.dispatch(
      new fromBaseState.Go({ path: [MainPageNavigation.LOGIN] })
    );
  }

  public onSocialLogin($event: string) {
    const pathAfterAuth: string = this.property
      ? `apply/${this.property.id}`
      : 'auth/portal-register';

    const stateAfterAuth: StateAfterAuth = {
      pathAfterAuth,
      queryParams: {
        socialLoginType: $event,
        socialLogin: true
      }
    };

    const redirectUri = getRedirectUrl(
      this.windowRef.location.toString(),
      '/auth',
      stateAfterAuth
    );

    const socialLoginConfirmation =
      this.modalService.open<SocialLoginConfirmationModalComponent>(
        SocialLoginConfirmationModalComponent,
        { centered: true }
      );
    socialLoginConfirmation.onClose().subscribe(() => {
      this.store.dispatch(
        new fromBaseState.UserLogin({
          redirectUri,
          loginMethod: $event
        })
      );
    });

    this.localStorageService.removeItem('landlordThemeToken');
  }

  private patchForm(data: UserRegisterData) {
    const { firstName, lastName, email } = this.form.value;
    const payload: Partial<UserRegisterData> = {};

    if (firstName) payload.firstName = data.firstName;
    if (lastName) payload.lastName = data.lastName || data.name;
    if (email) payload.email = email;

    if (Object.keys(payload).length > 0) this.form.patchValue(payload);
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(new fromBaseState.SetCookiesPreference(payload));
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(
      new fromBaseState.OpenCookieSettingsModal(payload, true)
    );
  }
}
