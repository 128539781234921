import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { LocalStorageService } from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import {
  DeclareIntentData,
  GuestApplication,
  NameValue,
  PropertyApplicationStatus,
  PropertyBean
} from '@ui/shared/models';

import * as fromAppState from 'tenant-pool/+state';
import { storageKeys } from 'tenant-pool/config';
import { isRentalObject } from 'libs/utils';

@UntilDestroy()
@Component({
  selector: 'app-guest-intent',
  templateUrl: './guest-intent.component.html',
  styleUrls: ['./guest-intent.component.scss']
})
export class GuestIntentComponent implements OnInit {
  public token: string;
  public application: GuestApplication;
  public property: PropertyBean;
  public hasVisitedViewingAppointment: string;
  public processing$: Observable<boolean>;

  public isInterested: boolean;
  public isNotInterested: boolean;

  public reasonTypeModel: string;
  public otherReasonText: string;
  public reasons: NameValue[];

  public get isSelfDisclosureRequired() {
    return !!this.property?.selfDisclosureId;
  }

  public get isInvalid() {
    return (
      !this.reasonTypeModel ||
      (this.reasonTypeModel === 'OTHER_REASON' && !this.otherReasonText)
    );
  }

  private get isRentalObject(): boolean {
    return isRentalObject(this.property.marketingType);
  }

  constructor(
    private store: Store<fromAppState.AppState>,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token || '';
    const value = this.route.snapshot.queryParams.value || '';

    this.isInterested = value === PropertyApplicationStatus.INTENT;
    this.isNotInterested = value === PropertyApplicationStatus.NO_INTENT;

    this.store
      .select(fromBaseState.getConstants)
      .pipe(
        filter(constants => !!constants),
        untilDestroyed(this)
      )
      .subscribe(constants => (this.reasons = constants.refusalReasonTypes));

    this.store
      .select(fromAppState.getGuestApplication)
      .pipe(
        filter(item => !!item),
        untilDestroyed(this)
      )
      .subscribe(application => {
        this.application = application;
        this.property = application.property;
      });

    this.processing$ = combineLatest([
      this.store.select(fromAppState.getGuestDeclareIntentLoading)
    ]).pipe(map(list => list.some(item => item)));
  }

  public onClickInterested() {
    if (this.isSelfDisclosureRequired && this.isRentalObject) {
      this.localStorageService.setItem(
        storageKeys.selfDisclosureIsDeclareIntent,
        true
      );
      this.localStorageService.setItem(
        storageKeys.selfDisclosureApplicationId,
        this.application.id
      );
      this.store.dispatch(
        new fromAppState.OpenGuestRegisterModal(
          this.application,
          this.token,
          true
        )
      );
    } else {
      this.intentAction({ intent: true }, this.token);
    }
  }

  public denyIntent() {
    const payload = {
      intent: false,
      reasonType: this.reasonTypeModel,
      otherReasonText: this.otherReasonText
    };
    this.intentAction(payload, this.token);
  }

  private watchIntentState() {
    this.store
      .select(fromAppState.getGuestDeclareIntentActionState)
      .pipe(
        filter(item => !!item && item.done),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(
          new fromBaseState.Go({
            path: ['guest'],
            query: { token: this.token }
          })
        );
      });
  }

  private intentAction(declareIntentData: DeclareIntentData, token: string) {
    this.watchIntentState();
    this.store.dispatch(
      new fromAppState.GuestDeclareIntent(declareIntentData, token)
    );
  }
}
