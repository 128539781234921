<app-cookie-banner
  *ngIf="(processing$ | async) === false"
  [cookiePreference]="cookiesPreference"
  (acceptAll)="acceptCookies($event)"
  (customSettings)="customCookieSettings($event)"
>
</app-cookie-banner>

<div *ngIf="(processing$ | async) === false; else loading" class="container">
  <div class="row mb20">
    <div class="col-6">
      <app-button [type]="'link'" *ngIf="isMobile" (clickEvent)="backToApp()">
        <a routerLink="/properties">{{'general.back_to_app_l' | translate}}</a>
      </app-button>
    </div>
    <div class="col-6">
      <span
        class="custom-cookie-settings"
        (click)="customCookieSettings(cookiesPreference)"
        >{{'general.change_cookie_settings_a' | translate}}</span
      >
    </div>
  </div>

  <ng-container *ngIf="(error$ | async) || (deleted$ | async)">
    <div class="status-logo__container">
      <img
        src="/assets/images/logos/Immomio_Logo_Kooperation.svg"
        alt="immomio-logo"
      />
    </div>

    <div *ngIf="(deleted$ | async)" class="mt30 deleted-container">
      <p class="deleted-title">
        {{'guest_mode.apply.landing_deleted_title_l' | translate}}
      </p>
    </div>

    <div *ngIf="(error$ | async)" class="mt30 error-container">
      <h3 class="error-title">
        {{'guest_mode.apply.landing_error_title_l' | translate}}
      </h3>
      <p class="error-text mt20">
        {{getErrorMessage((error$ | async).message) | translate}}
      </p>
      <div class="row justify-content-center">
        <div class="mt20 col-6 login-button">
          <app-button
            *ngIf="errorShowLogin((error$ | async).message)"
            (clickEvent)="onLogin()"
            [type]="'primary'"
            >{{'general.login_a' | translate}}</app-button
          >
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="(error$ | async) !== true && (deleted$ | async) !== true"
  >
    <div class="mb-2">
      <app-customer-info-legacy
        [name]="customerName"
        [logo]="customerLogo"
        [logoRedirectUrl]="getLogoRedirectUrl"
        [showOfferFrom]="false"
      ></app-customer-info-legacy>
    </div>

    <router-outlet></router-outlet>

    <div *ngIf="!isDelete" class="uppercase-m semibold header mt35 mb12">
      {{ (!isSales ? 'property.apply.header_l' :
      'property_sales.apply.header_l') | translate:{firstName:
      userData?.firstName, lastName: userData?.lastName } }}
    </div>

    <div *ngIf="isDelete">
      <p class="title-m">
        {{ 'guest_mode.application.delete_title' | translate:{propertyName:
        application.property.name} }}
      </p>

      <app-confirm-reason
        [(reasonTypeModel)]="reasonTypeModel"
        [(otherReasonText)]="otherReasonText"
        [reasons]="reasons"
      >
      </app-confirm-reason>

      <hr class="mt30 mb30" />

      <div class="row col-12 justify-content-end">
        <app-button
          [type]="'negative'"
          [disabled]="!reasonTypeModel"
          (clickEvent)="deleteApplication()"
          >{{ 'general.delete_l' | translate}}</app-button
        >
      </div>
    </div>

    <div class="property-basic__info mb24">
      <div class="property-basic__info-name-price">
        <div class="title-m basic-info__name">{{property?.data?.name}}</div>
        <div class="title-s basic-info__price">
          {{ (!isSales ? property?.data?.totalRentGross :
          property?.data?.salesData?.price) | currency:'EUR'}} / {{ (!isSales ?
          'general.total_rent_l' : 'general.purchase_price_l') | translate }}
        </div>
      </div>
      <div
        class="title-s property-basic__info-address"
        *ngIf="property?.data?.showAddress"
      >
        {{property?.data?.address | appAddress}}
      </div>
      <div class="mt20">
        <app-property-banner
          [applicantStatus]="application?.applicantStatus"
        ></app-property-banner>
      </div>

      <div *ngIf="!isSales" class="mb20 mt20 guest-register-container">
        <app-info-box>
          <div class="guest-register">
            <span
              class="guest-register__text"
              [innerHTML]="(application?.userProfile?.itpCustomerName ?
              'guest_mode.application.register_advantages_itp_l' : 'guest_mode.application.register_advantages_no_itp_l') | translate:{customerName: application?.userProfile?.itpCustomerName}"
            ></span>
            <span
              class="guest-register__link"
              (click)="register()"
              [innerHTML]="'guest_mode.application.register_now_l' | translate"
            ></span>
          </div>
        </app-info-box>
      </div>
    </div>

    <hr />

    <div *ngIf="image" class="property-image__container mb32">
      <img [src]="image | appImageSize:'L'" />
    </div>

    <div class="uppercase-m semibold property-details__header">
      {{'property.apply.property_details_l' | translate}}
    </div>

    <div class="property-details mb24 mt4">
      <div class="property-details__subdetail">
        <svg-icon
          src="/assets/images/icons/icon-area.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color rect line polyline path"
        ></svg-icon
        >{{ property?.data?.size | number }} m<sup>2</sup>
      </div>

      <div *ngIf="property?.data?.rooms" class="property-details__subdetail">
        <svg-icon
          src="/assets/images/icons/icon-rooms.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color rect line polyline path"
        ></svg-icon>
        {{ property?.data?.rooms | number:'1.0':'de' }} {{
        (!property?.data?.halfRooms ? 'general.rooms_l' : property?.data?.rooms
        === 1 ? 'general.whole_room_l' : 'general.whole_rooms_l') | translate }}
      </div>

      <div
        *ngIf="property?.data?.halfRooms"
        class="property-details__subdetail"
      >
        <svg-icon
          src="/assets/images/icons/icon-half-rooms.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color line polyline path"
        ></svg-icon>
        {{ property?.data?.halfRooms | number:'1.0':'de' }} {{
        (property?.data?.halfRooms === 1 ? 'general.half_room_l' :
        'general.half_rooms_l') | translate }}
      </div>

      <ng-container *ngIf="isSales; else bailmentTemplate">
        <div
          *ngIf="!property?.data?.courtageData?.courtageInPercent"
          class="property-details__subdetail"
        >
          {{ ( property?.data?.courtageData?.courtage || 0) | currency:'EUR' }}
          {{ 'general.courtage_l' | translate}}
        </div>

        <div
          *ngIf="property?.data?.courtageData?.courtageInPercent"
          class="property-details__subdetail"
        >
          {{ (property?.data?.courtageData?.courtage || 0)}} % {{
          'general.courtage_l' | translate}}
        </div>
      </ng-container>
      <ng-template #bailmentTemplate>
        <div class="property-details__subdetail">
          {{ (property?.data?.bailment || 0 | currency: 'EUR')}} {{
          'general.deposit_l' | translate}}
        </div>
      </ng-template>
    </div>

    <div *ngIf="!isSales" class="mt20">
      <app-profile-summary
        [form]="userDataForm"
        [userData]="userData"
        [guestMode]="true"
      >
      </app-profile-summary>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="property-apply__container property-loading">
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</ng-template>
