<app-cookie-banner
  *ngIf="(loading$ | async) === false"
  [cookiePreference]="cookiesPreference"
  (acceptAll)="acceptCookies($event)"
  (customSettings)="customCookieSettings($event)"
></app-cookie-banner>

<div
  *ngIf="(loading$ | async) === false; else loading"
  class="contract-verify__container"
>
  <div class="row mb12">
    <div class="col-6">
      <app-button [type]="'link'" *ngIf="isMobile" (clickEvent)="backToApp()">
        <a routerLink="/properties">{{'general.back_to_app_l' | translate}}</a>
      </app-button>
    </div>
    <div class="col-6">
      <span
        class="custom-cookie-settings"
        (click)="customCookieSettings(cookiesPreference)"
        >{{'general.change_cookie_settings_a' | translate}}</span
      >
    </div>
  </div>

  <app-customer-info-legacy
    [name]="property?.customerName"
    [logo]="customerLogo"
    [showOfferFrom]="false"
  ></app-customer-info-legacy>

  <div class="mt30">
    <div class="mb20">{{'digital_contract.verify_code_l' | translate }}</div>
  </div>

  <div class="contract-verify__button-container mt32 mb32">
    <app-button [type]="'primary'" (clickEvent)="verify()"
      >{{ 'digital_contract.verify_code_now_a' | translate }}</app-button
    >
  </div>
</div>

<ng-template #loading>
  <div class="contract-verify__container property-loading">
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</ng-template>
