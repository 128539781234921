import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import * as fromBaseState from 'libs/infrastructure/base-state';
import * as fromAppState from 'tenant-pool/+state';
import { MainPageNavigation } from 'tenant-pool/config';

@Component({
  selector: 'app-confirm-intent',
  template: ''
})
export class ConfirmIntentComponent implements OnInit {
  constructor(
    private store: Store<fromBaseState.BaseState>,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const applicationId = this.route.snapshot.params.applicationId || '';
    this.store.dispatch(fromAppState.CacheIntent({ applicationId }));
    this.store.dispatch(
      new fromBaseState.Go({
        path: [MainPageNavigation.EXPOSE, applicationId],
        query: {
          origin: `${MainPageNavigation.PROPERTIES}/${MainPageNavigation.APPLICATIONS}`
        }
      })
    );
  }
}
