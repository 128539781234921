import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-apply-sales-modal',
  templateUrl: './apply-sales-modal.component.html',
  styleUrls: ['./apply-sales-modal.component.scss']
})
export class ApplySalesModalComponent {
  @Input() propertyId: string;
  @Input() form: FormGroup;
  constructor(private ngbActiveModal: NgbActiveModal) {}

  public close(): void {
    this.ngbActiveModal.close();
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public apply() {
    const { email, ...profileData } = this.form.value;
    const payload = {
      profileData,
      email,
      propertyId: this.propertyId
    };
    this.ngbActiveModal.close(payload);
  }
}
