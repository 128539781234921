import {
  OnInit,
  Component,
  Inject,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ModalService } from 'libs/components/legacy/modal';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ToastService } from 'libs/components/legacy/toast';
import {
  EnvironmentConfig,
  I18nService,
  ScrollerService
} from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { UserConversionService } from 'libs/services';

import * as fromUserSelector from 'tenant-pool/+state/user/user.selectors';
import { ENVIRONMENT_CONFIG } from 'tenant-pool/core';
import { NewRelicService } from 'libs/infrastructure/data-privacy/new-relic.service';
import { ConversationSenderTypes } from 'libs/components/legacy/messenger/model/enum';
import { NewUpdateBannerComponent } from 'libs/components/legacy/new-update-banner/new-update-banner.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  public finishedLoaded$: Observable<boolean>;

  constructor(
    private store: Store<fromBaseState.BaseState>,
    private i18nService: I18nService,
    private viewContainerRef: ViewContainerRef,
    private toastService: ToastService,
    private scrollerService: ScrollerService,
    private themeService: ThemeService,
    private userConversionService: UserConversionService,
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfig,
    private newRelicService: NewRelicService,
    private swUpdate: SwUpdate,
    private modalService: ModalService
  ) {
    this.toastService.setRootViewContainerRef(this.viewContainerRef);
    this.i18nService.loadDefaultTranslations();
    this.scrollerService.enableAllRoutes();
    this.newRelicService.init();

    this.finishedLoaded$ = this.store.select(
      fromBaseState.getTranslationLoaded
    );
    this.store.dispatch(
      new fromBaseState.SetSender(ConversationSenderTypes.PROPERTYSEARCHER)
    );
    this.themeService.init(this.environment);
  }

  public ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter(
            (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
          )
        )
        .subscribe(() =>
          this.modalService
            .open<NewUpdateBannerComponent>(NewUpdateBannerComponent, {
              backdrop: 'static'
            })
            .onClose()
            .subscribe(() =>
              this.store.dispatch(new fromBaseState.UpdateApplicationSuccess())
            )
        );
    }
    this.store
      .select(fromUserSelector.getUserData)
      .pipe(
        filter(userData => !!userData && !!userData.id),
        map(propertySearcher =>
          this.userConversionService.convertPsUser(propertySearcher)
        ),
        take(1)
      )
      .subscribe(userData => {
        // initially load the preference from LS and enable tracking if configured
        this.store.dispatch(new fromBaseState.InitCookiesPreference(userData));
      });
  }
}
