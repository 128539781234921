<div class="saga-transfer-data">
  <div class="d-flex">
    <img
      class="immomio-logo"
      src="/assets/images/logos/Immomio_Logo_Kooperation.svg"
      alt="immomio-logo"
    />

    <img class="saga-logo" src="/assets/images/Logo_SAGA.png" alt="SAGA-logo" />
  </div>

  <div class="row col-lg-7 col-12">
    <p
      class="title-m"
      [innerHTML]="'application.saga_transfer_title_l' | translate"
    ></p>
    <div
      class="default-s"
      [innerHTML]="'application.saga_transfer_explanation_l' | translate"
    ></div>

    <div class="action-container">
      <ng-template
        [ngTemplateOutlet]="!(dataTransferActionState | async)?.done ? awaitingAction : responseDone"
        [ngTemplateOutletContext]="{pending: (dataTransferActionState | async)?.pending}"
      ></ng-template>
    </div>

    <p
      class="default-s grey-color mt20"
      [innerHTML]="'application.saga_transfer_action_container_explanation_p4_l' | translate"
    ></p>
  </div>
</div>

<ng-template #responseDone>
  <p
    class="default-s response-done"
    [innerHTML]="(isAccepted ? 'application.saga_transfer_accepted_l' : 'application.saga_transfer_rejected_l') | translate"
  ></p>
</ng-template>

<ng-template #awaitingAction let-pending="pending">
  <p
    class="title-m"
    [innerHTML]="'application.saga_transfer_action_container_title_l' | translate"
  ></p>

  <p
    class="default-s mb0"
    [innerText]="'application.saga_transfer_action_container_explanation_p1_l' |
      translate"
  ></p>

  <p>
    ({{ 'application.saga_transfer_action_container_explanation_p2_l' |
    translate }}
    <a
      class="data-privacy__link"
      [href]="'application.saga_transfer_action_container_explanation_data_privacy_href_l' | translate"
      rel="noopener noreferrer"
      target="_blank"
      >{{'application.saga_transfer_action_container_data_privacy_l' |
      translate}}</a
    >)
  </p>

  <p
    [innerText]="'application.saga_transfer_action_container_explanation_p3_l' | translate"
  ></p>

  <div class="action-container__buttons">
    <app-button
      class="action-container__buttons-reject"
      [disabled]="pending"
      (clickEvent)="transfer(false)"
      >{{'application.saga_reject_transfer_a' | translate}}</app-button
    >
    <app-button
      class="action-container__buttons-accept"
      [disabled]="pending"
      (clickEvent)="transfer(true)"
      >{{ 'application.saga_accept_transfer_a' | translate }}</app-button
    >
  </div>
</ng-template>
