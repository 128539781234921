import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CommonModule, ViewportScroller } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { IbanDirective } from 'ngx-iban';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ThemeUrls } from 'libs/infrastructure/theme/theme-urls';

import { UserConversionService } from 'libs/services/user-conversion.service';
import {
  ComponentsModule as SharedComponentsModule,
  ComponentsModuleConfig
} from 'libs/components';
import { AdblockDetectorService, DateTimeService } from 'libs/services';
import { DirectivesModule } from 'libs/directives';
import {
  InfrastructureModule,
  InfrastructureConfig,
  FreshchatWidgetService,
  AuthTokenService,
  EnvironmentConfig,
  BodyService
} from 'libs/infrastructure';
import { CustomSerializer } from 'libs/infrastructure/base-state';
import { ThemeModule } from 'libs/infrastructure/theme/theme.module';
import { ImmomioTheme } from 'libs/infrastructure/theme/themes';
import { PipesModule } from 'libs/pipes';

import { environment } from 'tenant-pool-env';
import { FormStorageService } from 'tenant-pool/auth/services';

import { Event, Router, Scroll } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';
import { KeycloakTenantAuthenticationModule } from 'libs/infrastructure/keycloak-authentication-module/keycloak-tenant-authentication.module';
import { NewRelicService } from 'libs/infrastructure/data-privacy/new-relic.service';
import { effects, appReducers, metaReducers } from './+state';
import { ComponentsModule } from './components';
import { CoreModule } from './core';
import { LandingsModule } from './screens/landings/landings.module';
import { AppComponent } from './app.component';
import { AppRouterModule } from './app.router';
import { storageKeys, languageConfig } from './config';

const infrastructureConfig: InfrastructureConfig = {
  environment,
  apolloCacheIds: {
    MessageSource: 'messageKey'
  },
  translationKeys: {
    meta: {
      description: 'meta.description'
    }
  },
  storageKeys,
  languages: languageConfig
};

const sharedComponentsModuleConfig: ComponentsModuleConfig = {
  googleApiConfig: {
    apiKey: environment.google_api_key
  }
};

const declarations = [AppComponent];

const providers = [
  DateTimeService,
  FreshchatWidgetService,
  AdblockDetectorService,
  AuthTokenService,
  UserConversionService,
  FormStorageService,
  BodyService,
  NewRelicService
];

const imports = [
  ServiceWorkerModule.register('/ngsw-worker.js', {
    enabled: !environment.development
  }),
  TranslateModule.forRoot(),
  BrowserModule,
  BrowserAnimationsModule,
  CommonModule,
  SharedComponentsModule.forRoot(sharedComponentsModuleConfig),
  PipesModule,
  DirectivesModule,
  FormsModule,
  ReactiveFormsModule,
  AngularSvgIconModule.forRoot(),
  NgbModule,
  StoreRouterConnectingModule.forRoot({
    serializer: CustomSerializer
  }),
  InfrastructureModule.forRoot(infrastructureConfig),
  AppRouterModule,
  StoreModule.forRoot(appReducers, { metaReducers }),
  EffectsModule.forRoot(effects),
  !environment.production
    ? StoreDevtoolsModule.instrument({ connectInZone: true })
    : [],
  ComponentsModule,
  CoreModule,
  LandingsModule,
  ThemeModule.forRoot({
    themes: [ImmomioTheme],
    active: (environment as EnvironmentConfig)?.app_design || ThemeUrls.IMMOMIO
  }),
  IbanDirective,
  KeycloakTenantAuthenticationModule.forRoot({
    deepLinksConfig: {
      deepLinkingScheme:
        (environment as EnvironmentConfig)?.deepLinkingScheme ||
        ThemeUrls.IMMOMIO
    },
    keycloakConfig: {
      jsonConfig: () => environment.keycloakConfig
    }
  })
];

@NgModule({
  imports,
  providers,
  declarations,
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events
      .pipe(
        debounceTime(500),
        filter((e: Event): e is Scroll => e instanceof Scroll)
      )
      .subscribe(e => {
        if (e.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}
