import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ComponentsModule as SharedComponentsModule } from 'libs/components';
import { DirectivesModule } from 'libs/directives';
import { PipesModule } from 'libs/pipes';

import { GuestFormService } from 'tenant-pool/auth/guest-registration/services/guest-form.service';
import { ComponentsModule } from 'tenant-pool/components';

import {
  PropertyApplyComponent,
  ConfirmIntentComponent,
  DeeplinkRedirectComponent,
  AskSelfDisclosureComponent,
  DenyIntentComponent,
  DigitalContractLandingComponent,
  VerifyCodeLandingComponent,
  SelectViewingAppointmentComponent,
  ProposalOfferedComponent,
  SagaTransferDataComponent,
  ApplySalesModalComponent,
  DirectApplyComponent
} from './components';
import { GuestModeLandingComponent } from './components/guest-mode/guest-mode-landing.component';
import { guestLandingComponents } from './components/guest-mode/components';

const components = [
  PropertyApplyComponent,
  ConfirmIntentComponent,
  AskSelfDisclosureComponent,
  DenyIntentComponent,
  DigitalContractLandingComponent,
  VerifyCodeLandingComponent,
  ...guestLandingComponents,
  GuestModeLandingComponent,
  SelectViewingAppointmentComponent,
  ProposalOfferedComponent,
  SagaTransferDataComponent,
  ApplySalesModalComponent,
  DeeplinkRedirectComponent,
  DirectApplyComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    PipesModule,
    TranslateModule,
    ComponentsModule,
    RouterModule,
    AngularSvgIconModule,
    NgbModule,
    DirectivesModule
  ],
  declarations: [...components],
  exports: [...components],
  providers: [GuestFormService]
})
export class LandingsModule {}
