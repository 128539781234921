<div *ngIf="isNotInterested" class="guest-intent">
  <p class="title-m property-interest__title">
    {{ 'application.not_interested_modal_title' | translate:{propertyName:
    property.data.name} }}
  </p>
  <p class="default-s property-interest__message mb30">
    {{ (isRentalObject ? 'application.not_interested_modal_message' :
    'application_sales.not_interested_modal_message') | translate }}
  </p>

  <app-confirm-reason
    [(reasonTypeModel)]="reasonTypeModel"
    [(otherReasonText)]="otherReasonText"
    [reasons]="reasons"
  >
  </app-confirm-reason>

  <hr class="mt30 mb30" />

  <div class="row col-12 justify-content-end">
    <app-button
      [type]="'negative'"
      [disabled]="isInvalid"
      (clickEvent)="denyIntent()"
      >{{ 'application.not_interested_a' | translate}}</app-button
    >
  </div>
</div>

<div *ngIf="isInterested" class="guest-intent">
  <div class="property-interest__body">
    <ng-container>
      <p class="title-m property-interest__title">
        {{ (isRentalObject ? 'guest_mode.interested.title_l' :
        'guest_mode_sales.interested_title_l' ) | translate:{propertyName:
        property?.data?.name} }}
      </p>
      <p
        *ngIf="isSelfDisclosureRequired"
        class="default-s property-interest__message"
        [innerHTML]="'guest_mode.interested.message_l' | translate"
      ></p>

      <div *ngIf="application.askForViewingAppointmentAttendance">
        <p class="default-s property-interest__checkbox-message">
          <span
            >{{ 'guest_mode.interested.appointment_check_msg_l' | translate
            }}</span
          >
          <span class="required-marker">*&nbsp;</span>
        </p>

        <app-radio-group
          required
          [isUnselectable]="false"
          [(ngModel)]="hasVisitedViewingAppointment"
        >
          <app-radio-button class="radio-group mb4" [value]="'YES'">
            {{ 'application.interested_modal.appointment_check_yes_l' |
            translate }}
          </app-radio-button>
          <app-radio-button class="radio-group mb4" [value]="'NO'">
            {{ (isRentalObject ?
            'application.interested_modal.appointment_check_no_l' :
            'application_sales_interested_modal.appointment_check_no_l') |
            translate }}
          </app-radio-button>
        </app-radio-group>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer property-interest__footer">
    <app-button
      [type]="'positive'"
      [disabled]="application.askForViewingAppointmentAttendance && !hasVisitedViewingAppointment || (processing$ | async)"
      [ngbTooltip]="!hasVisitedViewingAppointment ? ('application.interested_modal.appointment_check_tooltip_l' | translate) : ''"
      (clickEvent)="onClickInterested()"
    >
      {{ (isSelfDisclosureRequired ? 'application.interested_register_a' :
      'application.interested_a') | translate}}
    </app-button>
  </div>
</div>
