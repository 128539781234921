<app-cookie-banner
  *ngIf="(loading$ | async) === false"
  [cookiePreference]="cookiesPreference"
  (acceptAll)="acceptCookies($event)"
  (customSettings)="customCookieSettings($event)"
></app-cookie-banner>

<div
  *ngIf="(loading$ | async) === false; else loading"
  class="deny-intent__container"
>
  <div class="status-logo__container mb40">
    <img
      src="/assets/images/logos/Immomio_Logo_Kooperation.svg"
      alt="immomio-logo"
    />
  </div>

  <p class="title-m confirm-reason__title">
    {{ 'application.not_interested.reason_title' | translate }}
  </p>
  <p class="default-s confirm-reason__message mb30">
    {{ 'application.not_interested.reason_m' | translate }}
  </p>

  <app-confirm-reason
    [(reasonTypeModel)]="reasonTypeModel"
    [(otherReasonText)]="otherReasonText"
    [reasons]="reasons"
  >
  </app-confirm-reason>

  <hr class="mt30 mb30" />

  <div class="row col-12 justify-content-end">
    <app-button
      [type]="'primary'"
      [disabled]="!reasonTypeModel"
      (clickEvent)="onDenyIntent()"
      >{{ 'application.not_interested_a' | translate}}</app-button
    >
  </div>
</div>

<ng-template #loading>
  <div class="deny-intent__container constants-loading">
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</ng-template>
