import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  Appointment,
  AppointmentAcceptanceCancelReason,
  AppointmentInvitationState,
  NameValue
} from '@ui/shared/models';
import { ModalService } from 'libs/components/legacy/modal';
import { AppointmentsModalComponent } from 'libs/components/legacy/appointments/appointments-modal/appointments-modal.component';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { ConfirmReasonModalComponent } from 'libs/components/legacy/confirm-reason-modal/confirm-reason-modal.component';
import { sharedDialogConfig } from 'libs/config';
import {
  AppointmentAcceptanceShortInfo,
  AppointmentPropertyShortInfo,
  AppointmentsBundles,
  AppointmentSelection
} from '@ui/shared/models';
import * as fromAppState from 'tenant-pool/+state';

@UntilDestroy()
@Component({
  selector: 'app-guest-viewing',
  templateUrl: './guest-viewing.component.html',
  styleUrls: ['./guest-viewing.component.scss']
})
export class GuestViewingComponent implements OnInit {
  public appointmentsBundleData$: Observable<AppointmentsBundles[]>;
  public guestAppointmentsLoading$: Observable<boolean>;
  public token: string;
  public appointmentAcceptanceCancelReasonTypes: NameValue[];

  constructor(
    private store: Store<fromAppState.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token || '';

    this.store
      .select(fromBaseState.getConstants)
      .pipe(
        filter(constants => !!constants),
        untilDestroyed(this)
      )
      .subscribe(
        constants =>
          (this.appointmentAcceptanceCancelReasonTypes =
            constants.appointmentAcceptanceCancelReasonTypes)
      );

    this.store.dispatch(new fromAppState.LoadGuestAppointments(this.token));

    this.appointmentsBundleData$ = this.store.select(
      fromAppState.getGuestAppointmentsBundles
    );
    this.guestAppointmentsLoading$ = this.store.select(
      fromAppState.getGuestAppointmentsLoading
    );
  }

  public onExportToCalendar(application: {
    property: AppointmentPropertyShortInfo;
    appointment: Appointment;
  }) {
    this.store.dispatch(
      new fromAppState.ExportCalendarFile(application, this.token)
    );
  }

  public filterAppointments(
    appointments: Appointment[],
    appointmentAcceptances: AppointmentAcceptanceShortInfo[]
  ) {
    return appointments?.filter(
      appointment =>
        (appointment.state === AppointmentInvitationState.ACTIVE &&
          !appointment.full) ||
        appointmentAcceptances.some(
          appointmentAcceptance =>
            appointmentAcceptance.appointmentId === appointment.id
        )
    );
  }

  public onSelectAppointment(appointmentsBundles: AppointmentsBundles) {
    let appointments = appointmentsBundles.appointments.filter(
      appointment => appointment.maxInviteeCount > appointment.sizeOfAttendees
    );

    appointments = appointments
      .filter(
        appointment => appointment.maxInviteeCount > appointment.sizeOfAttendees
      )
      .filter(
        appointment =>
          !appointmentsBundles.appointmentInvitations.some(invitation => {
            return invitation.appointmentId === appointment.id;
          })
      );
    const exclusiveAppointments = appointmentsBundles.appointments
      .filter(
        appointment => appointment.maxInviteeCount > appointment.sizeOfAttendees
      )
      .filter(appointment =>
        appointmentsBundles.appointmentInvitations.some(invitation => {
          return invitation.appointmentId === appointment.id;
        })
      );

    const data = {
      ...appointmentsBundles,
      appointments,
      exclusiveAppointments
    };
    this.modalService
      .open<AppointmentsModalComponent>(AppointmentsModalComponent, { data })
      .onClose()
      .subscribe((result: AppointmentSelection) => {
        if (result.appointmentAcceptanceId === 'non_fitting') {
          return this.store.dispatch(
            new fromAppState.NoAppointmentFitting({
              applicationId: result.applicationId,
              reasonType:
                AppointmentAcceptanceCancelReason.APPOINTMENT_DOES_NOT_FIT
            })
          );
        }
        const action = new fromAppState.AcceptGuestAppointment(
          result.appointment.id.toString(),
          this.token
        );
        return this.store.dispatch(action);
      });
  }

  public onCancelAppointment(appointmentId: string) {
    this.modalService
      .open<ConfirmReasonModalComponent>(ConfirmReasonModalComponent, {
        data: {
          ...sharedDialogConfig.appointment.cancelAppointment,
          reasons: this.appointmentAcceptanceCancelReasonTypes
        }
      })
      .onClose()
      .subscribe(data =>
        this.store.dispatch(
          new fromAppState.CancelGuestAppointment(
            {
              ...data,
              appointmentId: appointmentId
            },
            this.token
          )
        )
      );
  }
}
