import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActionState } from 'libs/state-utils';
import { Go } from 'libs/infrastructure/base-state';
import * as fromAppState from 'tenant-pool/+state';

@Component({
  selector: 'app-saga-transfer-data',
  templateUrl: './saga-transfer-data.component.html',
  styleUrls: ['./saga-transfer-data.component.scss']
})
export class SagaTransferDataComponent implements OnInit {
  public dataTransferActionState: Observable<ActionState>;
  public token: string;
  public isAccepted: boolean;

  constructor(
    private store: Store<fromAppState.AppState>,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.token || '';
    if (!this.token) {
      this.store.dispatch(new Go({ path: ['/login'] }));
    }
    this.dataTransferActionState = this.store.select(
      fromAppState.getSagaDataTransferActionState
    );
  }

  public transfer(accept: boolean) {
    this.isAccepted = accept;
    this.store.dispatch(new fromAppState.SagaDataTransfer(accept, this.token));
  }
}
