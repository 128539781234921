<app-cookie-banner
  *ngIf="(loading$ | async) === false"
  [cookiePreference]="cookiesPreference"
  (acceptAll)="acceptCookies($event)"
  (customSettings)="customCookieSettings($event)"
></app-cookie-banner>

<div
  *ngIf="(loading$ | async) === false; else loading"
  class="contract-sign__container"
>
  <div class="row">
    <div class="col-6">
      <app-button [type]="'link'" *ngIf="isMobile" (clickEvent)="backToApp()">
        <a routerLink="/properties">{{'general.back_to_app_l' | translate}}</a>
      </app-button>
    </div>
    <div class="col-6">
      <span class="switch-lang" (click)="switchLang()"
        >{{'general.translate_page_a' | translate}}</span
      >
    </div>
  </div>

  <div class="row mb12 mt12">
    <div class="col-12">
      <span
        class="custom-cookie-settings"
        (click)="customCookieSettings(cookiesPreference)"
        >{{'general.change_cookie_settings_a' | translate}}</span
      >
    </div>
  </div>

  <div class="contract-sign__logo-container">
    <app-customer-info-legacy
      [name]="property?.customerName"
      [logo]="customerLogo"
      [showOfferFrom]="false"
    ></app-customer-info-legacy>
  </div>

  <div *ngIf="isEsOrQesDownload" class="mt30">
    <app-attachments-list>
      <div class="mb20">
        {{'digital_contract.sign_landing.download_contract_msg_l' | translate }}
      </div>
      <app-attachment-item
        [attachment]="contract.signedDocumentCombinedFile"
        [index]="1"
        [isDocument]="true"
        [disableDownload]="false"
        [showRemove]="false"
        (download)="downloadContract($event)"
      >
      </app-attachment-item>
    </app-attachments-list>
  </div>

  <div *ngIf="isAesDownload" class="mt30">
    <app-attachments-list>
      <div class="mb20">
        {{'digital_contract.sign_landing.download_contract_list_l' | translate
        }}
      </div>
      <app-attachment-item
        *ngFor="let attachment of contract.signedDocumentSingleFiles; index as i"
        [attachment]="attachment"
        [index]="i"
        [isDocument]="true"
        [disableDownload]="false"
        [showRemove]="false"
        (download)="downloadSingleFileContract($event)"
      >
      </app-attachment-item>
    </app-attachments-list>

    <app-attachments-list>
      <div class="mt20 mb20">
        {{'digital_contract.sign_landing.download_contract_archive_l' |
        translate }}
      </div>
      <app-attachment-item
        [attachment]="contract.signedDocumentArchiveFile"
        [index]="1"
        [isDocument]="true"
        [disableDownload]="false"
        [showRemove]="false"
        (download)="downloadArchiveContract($event)"
      >
      </app-attachment-item>
    </app-attachments-list>
  </div>

  <div *ngIf="signatureRequired" class="mt30">
    <div>
      <img src="/assets/images/icons/icon-checkbox-green.svg" />
      <span class="uppercase-m ml8"
        >{{ 'digital_contract.sign_landing.header_msg_l' | translate }}</span
      >
    </div>
    <div class="mt15">
      {{ 'digital_contract.sign_landing.header_sub_msg_l' | translate }}
    </div>
  </div>

  <div *ngIf="isActionRequired" class="mt30">
    <div>
      <svg-icon
        src="/assets/images/icons/icon-clock.svg"
        [applyClass]="true"
        [svgStyle]="{'width.px': 30}"
        class="secondary-svg-color path rect"
      ></svg-icon>
      <span class="uppercase-m ml8"
        >{{ 'digital_contract.sign_landing_action_required.header_msg_l' |
        translate }}</span
      >
    </div>
    <div class="mt15">
      {{ 'digital_contract.sign_landing_action_required.header_sub_msg_l' |
      translate }}
    </div>
  </div>

  <div *ngIf="property">
    <div class="uppercase-m semibold contract-sign__header mt24 mb12">
      {{'digital_contract.sign_landing.header_l' | translate}}
    </div>

    <div class="contract-sign__basic-info mb24">
      <div class="basic-info__name-price">
        <div class="title-m basic-info__name">{{property?.name}}</div>
        <div class="title-s basic-info__price">
          {{getTotalRent | currency:'EUR'}} / {{ 'general.total_rent_l' |
          translate }}
        </div>
      </div>
      <div class="title-s basic-info__address">
        {{property?.address | appAddress}}
      </div>
    </div>

    <div *ngIf="image" class="contract-sign__image-container mb32">
      <img [src]="image.url" />
    </div>

    <div class="uppercase-m semibold contract-sign__details-header">
      {{'property.apply.property_details_l' | translate}}
    </div>

    <div class="contract-sign__details mb24 mt4">
      <div class="property-subdetail">
        <svg-icon
          src="/assets/images/icons/icon-area.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color rect line polyline path"
        ></svg-icon
        >{{ property?.size | number }} m<sup>2</sup>
      </div>

      <div *ngIf="property?.rooms" class="property-subdetail">
        <svg-icon
          src="/assets/images/icons/icon-rooms.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color rect line polyline path"
        ></svg-icon>
        {{ property?.rooms | number:'1.0':'de' }} {{ (property?.rooms === 1 ?
        'general.whole_room_l' : 'general.whole_rooms_l') | translate }}
      </div>

      <div *ngIf="property?.halfRooms" class="property-subdetail">
        <svg-icon
          src="/assets/images/icons/icon-half-rooms.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color line polyline path"
        ></svg-icon>
        {{ property?.halfRooms | number:'1.0':'de' }} {{ (property?.halfRooms
        === 1 ? 'general.half_room_l' : 'general.half_rooms_l') | translate }}
      </div>

      <div *ngIf="property && property.bailment" class="property-subdetail">
        {{ property.bailment | currency:'EUR' }} {{ 'general.deposit_l' |
        translate}}
      </div>
    </div>
  </div>

  <div *ngIf="signatureRequired">
    <div class="contract-sign__info">
      {{ 'digital_contract.sign_landing.info_m' | translate }}
    </div>

    <div class="contract-sign__button-container mt32 mb32">
      <app-form-field class="d-flex mb-2">
        <app-checkbox
          [required]="true"
          [(ngModel)]="isTermsAccepted"
        ></app-checkbox>
        <span
          class="terms-text"
          [innerHTML]="'digital_contract.agree_with_terms_l' | translate"
        ></span>
      </app-form-field>
      <app-button
        [disabled]="(processing$ | async) || !contract || !isTermsAccepted"
        [loading]="processing$ | async"
        [type]="'primary'"
        (clickEvent)="sign()"
        >{{ 'digital_contract.sign_now_a' | translate }}</app-button
      >
    </div>
  </div>

  <div *ngIf="downloadReady && showShopCard" class="shop__container">
    <a href="https://umzugsshop.immomio.com" target="_blank">
      <img
        src="/assets/images/umzugsshop_promo_mobile.svg"
        (click)="trackRelocationShop()"
      />
    </a>
  </div>
</div>

<ng-template #loading>
  <div class="contract-sign__container property-loading">
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</ng-template>
