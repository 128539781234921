import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { InfrastructureConfig } from 'libs/infrastructure';

@Injectable()
export class NewRelicService {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(INFRASTRUCTURE_CONFIG)
    private infrastructureConfig: InfrastructureConfig
  ) {}

  public init() {
    const deploymentEnv = this.infrastructureConfig.environment.deploymentEnv;
    switch (deploymentEnv) {
      case 'integration':
      case 'production': {
        this.injectScript(deploymentEnv);
        break;
      }

      default:
        break;
    }
  }

  private injectScript(_path: string) {
    // Disable NR till BE adjusts CORS headers
    // const scriptEl = this.doc.createElement('script');
    // scriptEl.type = 'text/javascript';
    // scriptEl.src = `new-relic-${path}.js`;
    // this.doc.head.appendChild(scriptEl);
  }
}
