<app-cookie-banner
  *ngIf="!(loading$ | async)?.pending"
  [cookiePreference]="cookiesPreference"
  (acceptAll)="acceptCookies($event)"
  (customSettings)="customCookieSettings($event)"
></app-cookie-banner>

<div class="direct-apply-page">
  <div class="container container--with-spacing">
    <ng-container
      *ngIf="!(loading$ | async)?.pending && !existingSession; else loading"
    >
      <app-register-card
        [form]="form"
        [property]="property"
        [disabled]="registerProcessing$ | async"
        [errorMessage]="registerError$ | async"
        [socialLogin]="socialLogin"
        [guestLoginRedirectUrl]="'/guest-register'"
        [showLoginAsGuest]="property?.allowContinueAsGuest"
        (registerEvent)="onRegister()"
        (loginRedirectEvent)="onLoginRedirect()"
        (socialLoginEvent)="onSocialLogin($event)"
      ></app-register-card>
    </ng-container>

    <ng-template #loading>
      <div class="loader">
        <div class="loading-area">
          <app-loading-spinner></app-loading-spinner>
        </div>
      </div>
    </ng-template>
  </div>
</div>
