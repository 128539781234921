import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { LocalStorageService } from 'libs/infrastructure';

import { storageKeys } from 'tenant-pool/config';

@Component({
  selector: 'app-ask-self-disclosure',
  template: ''
})
export class AskSelfDisclosureComponent implements OnInit {
  constructor(
    private store: Store<fromBaseState.BaseState>,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit() {
    const applicationId = this.route.snapshot.params.applicationId || null;

    this.localStorage.setItem(
      storageKeys.selfDisclosureApplicationId,
      applicationId
    );

    this.store.dispatch(
      new fromBaseState.Go({
        path: ['/properties']
      })
    );
  }
}
